
@import "common";



.home-top-pan {
  width: 100%;
  height: 200px;
  background-color: $color_back_light;
  position: relative;
  z-index: 20;

  .home-top-pan-logo {

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-top: 40px solid transparent;
    }

    h1 {
      font-size: 1.7em;
      text-align: center;
      margin-left: 160px;
      font-style: italic;
      color: white;
      margin-top: 0;
    }
  }
}


.home-body-area {

  max-width: $area_max_width;
  margin: 20px 40px 100px 40px;

  .home-section {

    padding: 0 25px;
    margin-top: 30px !important;

    div {
      text-align: center;

      span {
        font-size: 5em;
        color: $color-primary !important;

        svg {
          width: 100%;
        }
      }
    }

    h2 {
      font-family: "Chivo", serif;
      font-size: 2em;
      color: $color-contrast;
      text-align: center;
      margin: 8px 0 8px 0;
    }

    article {
      font-family: "Montserrat", serif;
      font-size: 1.5em;
      line-height: 1.4em;
      color: $color-primary-text-black;
      text-align: center;
    }

    a {
      text-decoration: none;
      color: $color-secondary-text;
      font-size: 1.3em;
      float: right;
      margin: 12px 25px 0 0;

      &:hover {
        text-decoration: none;
      }
    }
  }

}