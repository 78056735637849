
@import "common";


.carbon-top-pan {
  width: 100%;
  height: 200px;
  background-color: $color_back_light_green;
  position: relative;
  z-index: 20;

  .carbon-top-pan-logo {

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-top: 40px solid transparent;
    }

    h1 {
      font-size: 1.7em;
      text-align: center;
      margin-left: 160px;
      font-style: italic;
      color: #218c46;
      margin-top: 0;
    }
  }
}


.carbon-prologo-area {

  max-width: $area_large_max_width;

  .carbon-prologo {

    width: 90%;
    margin: 40px auto 30px auto;
    text-align: justify;
    font-size: 16px;
    line-height: 30px;

    @media (min-width: $media_tablet) {
      width: 50%;
    }
    @media (min-width: $media_monitor) {
      width: 40%;
    }
  }
}



.carbon-body-area {

  max-width: $area_medium_max_width;
  margin: 0 auto;
  padding: 0 10px 15px 20px !important;

  @media (min-width: $media_tablet) { max-width: 70%; }
  @media (min-width: $media_monitor) { max-width: $area_medium_max_width; }


  &.elettrico-area {
    background: rgba(16, 162, 243, 0.05);
  }

  &.riscalda-area {
    background: rgba(241, 97, 20, 0.05);
  }

  &.trasporto-area {
    background: rgba(117, 187, 32, 0.05);
  }

  &.climate-clock-area {
    background: rgba(191, 26, 222, 0.05);
  }

  .carbon-section {

    padding: 0 25px;
    margin-top: 30px !important;
    text-align: justify;
    font-size: 16px;
    line-height: 30px;

    .carbon-strategy {
      color: green;
      font-weight: bold;
    }

    .carbon-icon-section {

      div {
        display: flex;

      }

      p {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: $color-primary;
        margin-top: 4px;
      }

      svg {
        width: 100%;
        text-align: center;
        font-size: 70px;
      }

      .elettrico {
        color: #10a2f3;
      }

      .riscalda {
        color: #f16114;
      }

      .trasporto {
        color: #75bb20;
      }

      .climate-clock {
        color: #bf1ade;
      }
    }

  }

}

.bottom-space {

  margin-bottom: 100px;
}

//
// Climate Clock override
//
#climate-clock-container {

  width: 100%;

  .climate-clock-center {

    max-width: 500px;
    margin: 0 auto;

    #ccw-container-1 {

      margin-top: 20px;

      ccw-brand {
        background-color: #fbf2fd !important;
        color: #2d970f !important;
      }

      ccw-div {
        background-color: #fbf2fd !important;

        ccw-span {
          background-color: inherit !important;
        }
      }

      ccw-panel[lifeline] {

        ccw-span {
          color: #2d970f !important;
        }
      }


      ccw-ticker {
        background-color: inherit !important;
        color: #2d970f !important;
      }
    }
  }

}


