
@import "common";



.azienda-body-back {

  img {
    position: absolute;
    display: block;
    left: 30px;
    top: 100px;
    width: 100%;
    max-width: 1080px;
    z-index: 5;
  }
}


.azienda-body-area {

  max-width: $area_max_width;
  margin: 20px auto 0 auto;


  .azienda-section {

    position: center;
    z-index: 10;
    width: 100%;
    min-height: 600px;
  }

  .azienda-section-white {

    background-color: white;
    opacity: 0.9;
  }

  .azienda-section-gradient {

    background-image: linear-gradient(to left, rgba(255,255,255,.9), rgba(0,0,0,0));

    text-align: center;

    h2 {
      font-family: "Chivo", serif;
      color: $color-primary-text-black;
      font-size: 3.2em;
    }
  }

  article {

    margin: 30px 20px 0 0;

    @media (min-width: $media_smartphone) {

      margin-left: 25px;
    }

    @media (min-width: $media_monitor) {

      margin-left: 25px;
    }

    p {

      font-family: "Chivo", serif;
      font-size: 1.4em;
      line-height: 1.5em;
      color: $color-primary-text-black;
    }
  }

}

