
@import "common";


// region COMMON

.prodotti-body-area {

  max-width: $area_max_width;
  margin: 20px auto 100px auto;
}


#prodotti-body {

  width: 100%;

  h2 {
    font-family: "Chivo", serif;
    color: $color-primary-text-black;
    font-size: 3.2em;
    text-align: center;
    margin: 50px 0 10px 0;
  }

  .home-section {


    margin-top: 60px;
    padding: 0 32px 0 32px;

    .dashboard-hat {
      border-top: 10px solid $color-primary;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    h3 {
      display: inline;
      font-size: 2em;
      padding-left: 20px;
      color: $color-primary-text-black;
      font-weight: bold;
      cursor: pointer;
    }

    article {
      border-top: 1px dotted $color-primary-text;
      margin: 12px 0 0 16px;
      text-align: justify;
      font-family: "Chivo", serif;
      font-size: 1.4em;
      line-height: 1.5em;
      color: $color-primary-text-black;

      a {
        text-decoration: none;
        color: $color-contrast;
      }
    }

    span {
      float: right;
      cursor: pointer;
      margin-top: 12px;
      font-size: 1.6em;
    }

    &.overflow-image {
      overflow-x: hidden;
      overflow-y: hidden;

      @media (min-width: $media_smartphone) {

        min-height: 320px;
      }

      @media (min-width: $media_tablet) {

        min-height: 470px;
      }

      img {
        display: flex;
        width: 150%;
        margin: 0 auto -320px auto;
        opacity: 1.0;
      }


    }
  }

  .products-dash-second-row {


    //margin-bottom: 80px;
  }


}

.prodotti-title-bar {

  display: flex;
  margin-top: 32px;

  h1 {
    color: $color-primary;
    font-size: 2em;
    margin: 0 auto;
  }
}

.prodotti-standard-intro {
  font-size: 1.3em;
  color: $color-secondary-text;
  font-family: "Chivo", serif;

  a {
    color: $color-primary-text;
    text-decoration: none;

    &:hover {
      color: $color-primary-text;
      text-decoration: none;
    }
  }
}

.zoom-img {
  border-radius: 2px;
}

// endregion




// region NEXTCLOUD

#nextcloud-body-area {

  margin: 0 16px;
}

#nextcloud-body-area-line01 {
  margin-top: 32px;
  color: $color-contrast;
}

#nextcloud-body-area-line02 {
  margin: 32px 30px 0 30px;
  font-size: 1.3em;
  color: $color-secondary-text;
  text-align: center;
  font-family: "Chivo", serif;

  p {
    font-size: 0.9em;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }

  @media (min-width: $media_tablet) { top: 102px; }
  @media (min-width: $media_monitor) { margin-left: 80px; margin-right: 80px; }
  @media (min-width: $media_over) { margin-left: 160px; margin-right: 160px; }
}

#nextcloud-body-area-img01 {
  position: absolute;
  right: 20px;
  top: 112px;
  width: 12%;
  min-width: 40px;
  max-width: 170px;

  @media (min-width: $media_tablet) { top: 102px; }
  @media (min-width: $media_monitor) { top: 90px; }
  @media (min-width: $media_over) { top: 90px; }
}

#nextcloud-body-area-intro {
  background-color: $color_main_back;
  margin: 16px -16px 0 -16px;
  padding-bottom: 16px;
}

#nextcloud-3imgs-section {

  div {
    div {
      align-content: center;
      display: block;
    }
  }

  h2 {
    margin-top: 16px;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }

  img {
    display: flex;
    width: 70%;
    margin: 0 auto;
    fill: $color-contrast !important;
    border-radius: 8px;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
    cursor: zoom-out;
  }

  p {
    margin: 8px 20px 0 20px;
    text-align: center;
    font-size: 1.1em;
    color: $color-secondary-text;
    font-family: "Chivo", serif;
  }

}

#nextcloud-software-section {

  margin: 32px -16px 0 -16px;
  max-height: 320px;
  overflow-y: hidden;

  @media (min-width: $media_tablet) { max-height: 250px; }
  @media (min-width: $media_monitor) { max-height: 200px; }
  @media (min-width: $media_over) { max-height: 200px; }

  div {
    div {
      align-content: center;
      display: block;

      img {
        width: 100%;
      }
    }
  }

}

#nextcloud-software-section-second {

  background-color: $color_main_back;
  padding-bottom: 16px;

  h2 {
    margin-top: 16px;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }

  p {
    margin: 8px 20px 0 20px;
    text-align: center;
    font-size: 1.2em;
    color: $color-secondary-text;
    font-family: "Chivo", serif;

    a {
      text-decoration: none;
      color: $color-primary-text;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#nextcloud-preventivo {
  margin: 0 -16px 0 -16px;
  padding: 32px 0 32px 0;
  background-color: $color-back-contrast;

  article {
    margin: 16px 15% 0 15%;
    text-align: center;
    color: $color-secondary;
    font-family: "Chivo", serif;
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.5em;
    color: $color-contrast;
    text-align: center;
    font-family: "Chivo", serif;
  }


}

#nextcloud-preventivo-form {
  margin: 16px 5px 16px 5px;

  @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
  @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
  @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

  .nextcloud-preventivo-form-ctrl-label {
    margin: 16px 0 3px 16px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .nextcloud-preventivo-form-ctrl-switch {

    display: flex;
    margin-top: 8px;

    span {
      color: $color-secondary-text;
      font-size: 1.2em;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .submitNextcloud {
    margin-top: 16px;
  }

  #next-opt-submit {
    display: block;
    margin: 0 auto;
    background-color: $color-contrast;
    color: $color-primary-text-reverse;
    font-weight: bold;
  }
}

// endregion




// region SMSWAY

#smsway-body-area {

  margin: 0 16px;
}

#smsway-body-area-line01 {
  margin-top: 32px;
  color: $color-contrast;
}

#smsway-body-area-img01 {
  position: absolute;
  right: 20px;
  top: 112px;
  width: 12%;
  min-width: 40px;
  max-width: 80px;
}

#smsway-body-area-intro {
  background-color: $color_main_back;
  margin: 16px -16px 0 -16px;
  padding-bottom: 16px;

  img {
    z-index: 10;
    position: relative;
    left: -45px;
    top: 20px;
    width: 300px;
    transform: rotate(-10deg);

    @media (min-width: $media_monitor) { left: 64px; }
    @media (min-width: $media_over) { left: 140px; }
  }

  p {
    font-size: 0.9em;
    position: relative;
    z-index: 20;
  }
}

#smsway-body-area-line02 {
  margin: 32px 30px 0 30px;
  font-size: 1.3em;
  color: $color_secondary_text;
  text-align: center;
  font-family: "Chivo", serif;

  li {
    font-size: 1.2em;
  }
}

#smsway-3imgs-section {

  div {
    div {
      align-content: center;
      display: flex;

      img {
        width: 200px;
        margin: 0 auto;
      }
    }
  }

  h2 {
    margin: 32px 0 32px 0;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }
}

#smsway-3imgs-section-center {
  display: block !important;

  img {
    display: flex;
    width: 150px !important;
    margin: 0 auto 24px auto !important;
    opacity: 0.8;
  }

  p {
    text-align: center;
    color: $color-secondary-text;
    font-size: 1.2em;
    font-family: "Chivo", serif;

    a {
      text-decoration: none;
      color: $color-primary-text;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#smsway-api-demo-section {

  margin: 32px -16px 0 -16px;

  div {
    div {

      padding-bottom: 16px;

      &:first-child {
        background-color: $color-back-gray;
      }

      &:last-child {
        background-color: $color_main_back;
      }

      h2 {
        margin: 16px 0 32px 0;
        font-size: 1.5em;
        color: $color-primary-text;
        text-align: center;
        font-family: "Chivo", serif;
      }

      p {
        color: $color-secondary-text;
        font-family: "Chivo", serif;
        font-size: 1.2em;
        padding: 0 32px;
        text-align: justify;

        a {
          color: $color-primary-text;
          text-decoration: none;
          font-weight: bold;

          &:hover {
            color: $color-primary-text;
            text-decoration: none;
          }
        }

        @media (min-width: $media_monitor) { padding: 0 64px; }
        @media (min-width: $media_over) { padding: 0 96px; }
      }
    }
  }
}

#smsway-info {
  margin: 0 -16px 0 -16px;
  padding: 32px 0 32px 0;
  background-color: $color-back-contrast;

  h3 {
    font-size: 1.5em;
    color: $color-contrast;
    text-align: center;
    font-family: "Chivo", serif;
  }

  h2 {
    margin: 16px 15% 0 15%;
    text-align: center;
    color: $color-secondary;
    font-family: "Chivo", serif;
    font-size: 1.2em;
    line-height: 26px;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }
}

#smsway-info-form {
  margin: 16px 5px 16px 5px;

  @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
  @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
  @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

  .smsway-info-form-ctrl-label {
    margin: 16px 0 3px 16px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .smsway-info-form-ctrl-switch {

    display: flex;
    margin-top: 8px;

    span {
      color: $color-secondary-text;
      font-size: 1.2em;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .submitSmsway {
    margin-top: 16px;
  }

  #smsway-opt-submit {
    display: block;
    margin: 0 auto;
    background-color: $color-contrast;
    color: $color-primary-text-reverse;
    font-weight: bold;
  }
}

// endregion




// region DNSUP

#dnsup-body-area {

  margin: 0 16px;
}

#dnsup-body-area-line01 {
  margin-top: 32px;
  color: $color-contrast;
}

#dnsup-body-area-img01 {
  position: absolute;
  right: 20px;
  top: 112px;
  width: 12%;
  min-width: 40px;
  max-width: 80px;
}

#dnsup-body-area-intro {
  background-color: $color_main_back;
  margin: 16px -16px 0 -16px;
  padding-bottom: 16px;

  img {
    z-index: 10;
    position: relative;
    left: -22px;
    top: 60px;
    width: 300px;

    @media (min-width: $media_monitor) { left: 64px; }
    @media (min-width: $media_over) { left: 140px; }
  }

  p {
    font-size: 0.9em;
    position: relative;
    z-index: 20;
  }
}

#dnsup-body-area-line02 {
  margin: 32px 30px 0 30px;
  font-size: 1.3em;
  color: $color_secondary_text;
  text-align: justify;
  font-family: "Chivo", serif;

  .line02-center {

    text-align: center;

    li {
      font-size: 1.2em;
    }
  }
}

#dnsup-3imgs-section {

  div {
    div {
      align-content: center;
      display: block;
    }
  }

  h2 {
    margin-top: 16px;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }

  img {
    display: flex;
    height: 100px;
    margin: 0 auto;
    fill: $color-contrast !important;
  }

  p {
    margin: 8px 20px 0 20px;
    text-align: center;
    font-size: 1.1em;
    color: $color-secondary-text;
    font-family: "Chivo", serif;

    a {
      text-decoration: none;
      color: $color-primary-text;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#dnsup-gdpr-piani-section {

  margin: 32px -16px 0 -16px;

  div {
    div {

      padding-bottom: 16px;

      &:first-child {
        background-color: $color-back-gray;
      }

      &:last-child {
        background-color: $color_main_back;

        ul {
          text-align: center;
          font-size: 1.2em;
        }
      }

      h2 {
        margin: 16px 0 32px 0;
        font-size: 1.5em;
        color: $color-primary-text;
        text-align: center;
        font-family: "Chivo", serif;
      }

      p {
        color: $color-secondary-text;
        font-family: "Chivo", serif;
        font-size: 1.2em;
        padding: 0 32px;
        text-align: justify;

        a {
          color: $color-primary-text;
          text-decoration: none;
          font-weight: bold;

          &:hover {
            color: $color-primary-text;
            text-decoration: none;
          }
        }

        @media (min-width: $media_monitor) { padding: 0 64px; }
        @media (min-width: $media_over) { padding: 0 96px; }
      }
    }
  }
}

#dnsup-info {
  margin: 0 -16px 0 -16px;
  padding: 32px 0 32px 0;
  background-color: $color-back-contrast;

  h3 {
    font-size: 1.5em;
    color: $color-contrast;
    text-align: center;
    font-family: "Chivo", serif;
  }

  h2 {
    margin: 16px 15% 0 15%;
    text-align: center;
    color: $color-secondary;
    font-family: "Chivo", serif;
    font-size: 1.2em;
    line-height: 26px;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }
}

#dnsup-info-form {
  margin: 16px 5px 16px 5px;

  @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
  @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
  @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

  .dnsup-info-form-ctrl-label {
    margin: 16px 0 3px 16px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .dnsup-info-form-ctrl-switch {

    display: flex;
    margin-top: 8px;

    span {
      color: $color-secondary-text;
      font-size: 1.2em;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .submitDnsup {
    margin-top: 16px;
  }

  #dnsup-opt-submit {
    display: block;
    margin: 0 auto;
    background-color: $color-contrast;
    color: $color-primary-text-reverse;
    font-weight: bold;
  }
}

// endregion




// region OLOGO

#ologo-body-area {

  margin: 0 16px;

  #ologo-body-area-img01 {
    position: absolute;
    right: 20px;
    top: 112px;
    width: 12%;
    min-width: 40px;
    max-width: 80px;
  }

  #ologo-body-area-line01 {
    margin-top: 32px;
    color: $color-contrast;
  }

  #ologo-body-area-intro {
    background-color: $color_main_back;
    margin: 16px -16px 0 -16px;
    padding-bottom: 16px;

    img {
      width: 100%;
      margin-top: 38px;
      margin-left: -20px;
      position: relative;
    }

    p {
      font-size: 0.9em;
      position: relative;
      z-index: 20;
    }
  }

  #ologo-body-area-line02 {
    margin: 32px 30px 0 30px;
    font-size: 1.3em;
    color: $color_secondary_text;
    text-align: justify;
    font-family: "Chivo", serif;

    a {
      text-decoration: none;
    }

    .line02-center {

      text-align: center;

      ul {
        text-align: left;

        li {
          font-size: 1.2em;
        }
      }
    }
  }

  #ologo-3imgs-section {

    article {
      margin-top: 16px;
      text-align: center;
      font-size: 16px;
      color: $color-secondary-text;

      a {
        text-decoration: none;
      }
    }

    div {
      div {
        align-content: center;
        display: block;
      }
    }

    h2 {
      margin-top: 16px;
      font-size: 1.5em;
      color: $color-primary-text;
      text-align: center;
      font-family: "Chivo", serif;
    }

    img {
      display: flex;
      height: 100px;
      margin: 0 auto;
      fill: $color-contrast !important;
      border-radius: 8px;
      -moz-box-shadow: 0 0 3px #ccc;
      -webkit-box-shadow: 0 0 3px #ccc;
      box-shadow: 0 0 3px #ccc;
      cursor: zoom-out;
    }

    p {
      margin: 8px 20px 0 20px;
      text-align: center;
      font-size: 1.1em;
      color: $color-secondary-text;
      font-family: "Chivo", serif;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  #ologo-demo-section {

    margin: 32px -16px 0 -16px;
    max-height: 320px;
    overflow-y: hidden;

    @media (min-width: $media_tablet) { max-height: 250px; }
    @media (min-width: $media_monitor) { max-height: 200px; }
    @media (min-width: $media_over) { max-height: 200px; }

    .demo-img-box {
      align-content: center;
      display: block;
      background-color: $color_back_gray;

      img {
        width: 100%;
      }
    }

    #ologo-demo-section-second {

      background-color: $color_main_back;
      padding-bottom: 16px;

      h2 {
        margin-top: 16px;
        font-size: 1.5em;
        color: $color-primary-text;
        text-align: center;
        font-family: "Chivo", serif;
      }

      p {
        margin: 8px 20px 0 20px;
        text-align: center;
        font-size: 1.2em;
        color: $color-secondary-text;
        font-family: "Chivo", serif;

        a {
          text-decoration: none;
          color: $color-primary-text;

          &:hover {
            text-decoration: none;
          }
        }
      }

    }
  }

  #ologo-info {
    margin: 0 -16px 0 -16px;
    padding: 32px 0 32px 0;
    background-color: $color-back-contrast;

    h3 {
      font-size: 1.5em;
      color: $color-contrast;
      text-align: center;
      font-family: "Chivo", serif;
    }

    h2 {
      margin: 16px 15% 0 15%;
      text-align: center;
      color: $color-secondary;
      font-family: "Chivo", serif;
      font-size: 1.2em;
      line-height: 26px;

      a {
        color: $color-primary-text;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: $color-primary-text;
          text-decoration: none;
        }
      }
    }
  }

  #ologo-info-form {
    margin: 16px 5px 16px 5px;

    @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
    @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
    @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

    .ologo-info-form-ctrl-label {
      margin: 16px 0 3px 16px;
      font-weight: bold;
      font-size: 1.2em;
    }

    .ologo-info-form-ctrl-switch {

      display: flex;
      margin-top: 8px;

      span {
        color: $color-secondary-text;
        font-size: 1.2em;

        a {
          text-decoration: none;
          color: $color-primary-text;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .submitologo {
      margin-top: 16px;
    }

    #ologo-opt-submit {
      display: block;
      margin: 0 auto;
      background-color: $color-contrast;
      color: $color-primary-text-reverse;
      font-weight: bold;
    }
  }
}

// endregion










