
@import "common";



// region COMMON

#servizi-body {

  width: 100%;

  h2 {
    font-family: "Chivo", serif;
    color: $color-primary-text-black;
    font-size: 3.2em;
    text-align: center;
    margin: 50px 0 10px 0;
  }

  #servizi-body-intro {

    p {
      font-family: "Chivo", serif;
      color: $color-primary-text-black;
      font-size: 1.2em;
      text-align: justify;
      margin: 16px 50px 0 50px;
    }
  }

  .home-section {

    margin-top: 60px;
    padding: 0 32px 0 32px;

    .dashboard-hat {
      border-top: 10px solid $color-primary;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    h3 {
      display: inline;
      font-size: 2em;
      padding-left: 20px;
      color: $color-primary-text-black;
      font-weight: bold;
      cursor: pointer;
    }

    article {
      border-top: 1px dotted $color-primary-text;
      margin: 12px 0 0 16px;
      text-align: justify;
      font-family: "Chivo", serif;
      font-size: 1.4em;
      line-height: 1.5em;
      color: $color-primary-text-black;
    }

    span {
      float: right;
      cursor: pointer;
      margin-top: 12px;
      font-size: 1.6em;
    }

    &.overflow-image {
      overflow-x: hidden;
      overflow-y: hidden;
      width: auto;
      margin: 0 auto;

      @media (min-width: $media_smartphone) {

        min-height: 320px;
      }

      @media (min-width: $media_tablet) {

        min-height: 470px;
      }

      img {
        display: flex;
        width: 100%;
        margin: 70px auto 0 auto;
        opacity: 0.8;
        min-width: 600px;
      }


    }

    .inside-section {
      margin-top: 100px;
    }
  }

}

.servizi-title-bar {

  display: flex;
  margin-top: 32px;

  h1 {
    color: $color-primary;
    font-size: 2em;
    margin: 0 auto;
  }
}

.servizi-standard-intro {
  font-size: 1.3em;
  color: $color-secondary-text;
  font-family: "Chivo", serif;

  a {
    color: $color-primary-text;
    text-decoration: none;

    &:hover {
      color: $color-primary-text;
      text-decoration: none;
    }
  }
}

// endregion




// region SISTEMI

#sistemi-body-area {

  margin: 0 16px;
}

#sistemi-body-area-line01 {
  margin-top: 32px;
  color: $color-contrast;
}

#sistemi-body-area-intro {
  background-color: $color_main_back;
  margin: 16px -16px 0 -16px;
  padding-bottom: 16px;

  img {
    z-index: 10;
    position: relative;
    left: 55px;
    top: 36px;
    width: 180px;

    @media (min-width: $media_tablet) { left: 55px; }
    @media (min-width: $media_monitor) { left: 140px; }
    @media (min-width: $media_over) { left: 200px; }
  }

  p {
    font-size: 0.9em;
    position: relative;
    z-index: 20;
  }
}

#sistemi-body-area-line02 {
  margin: 32px 30px 0 30px;
  font-size: 1.3em;
  color: $color_secondary_text;
  text-align: justify;
  font-family: "Chivo", serif;

  .line02-center {

    text-align: center;

    li {
      font-size: 1.2em;
    }
  }
}

#sistemi-2box-section {

  p {
    margin: 16px 30px 0 30px;
    font-size: 1.2em;
    color: $color_secondary_text;
    text-align: justify;
    font-family: "Chivo", serif;
  }

  h2 {
    margin-top: 16px;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }

  img {
    display: flex;
    width: 78%;
    max-width: 480px;
    margin: -12px auto 0 auto;
  }

  article {
    li {
      font-size: 1.2em;
      color: $color_secondary_text;
      text-align: center;
      font-family: "Chivo", serif;
    }
  }
}

#sistemi-analisi-monitor-section {

  margin: 32px -16px 0 -16px;

  div {
    div {

      padding-bottom: 16px;

      &:first-child {
        background-color: $color-back-gray;
      }

      &:last-child {
        background-color: $color_main_back;

        ul {
          text-align: center;
          font-size: 1.2em;
        }
      }

      h2 {
        margin: 16px 0 32px 0;
        font-size: 1.5em;
        color: $color-primary-text;
        text-align: center;
        font-family: "Chivo", serif;
      }

      p {
        color: $color-secondary-text;
        font-family: "Chivo", serif;
        font-size: 1.2em;
        padding: 0 32px;
        text-align: justify;

        a {
          color: $color-primary-text;
          text-decoration: none;
          font-weight: bold;

          &:hover {
            color: $color-primary-text;
            text-decoration: none;
          }
        }

        @media (min-width: $media_monitor) { padding: 0 64px; }
        @media (min-width: $media_over) { padding: 0 96px; }
      }
    }
  }
}

#sistemi-info {
  margin: 0 -16px 0 -16px;
  padding: 32px 0 32px 0;
  background-color: $color-back-contrast;

  h3 {
    font-size: 1.5em;
    color: $color-contrast;
    text-align: center;
    font-family: "Chivo", serif;
  }

  h2 {
    margin: 16px 15% 0 15%;
    text-align: center;
    color: $color-secondary;
    font-family: "Chivo", serif;
    font-size: 1.2em;
    line-height: 26px;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }
}

#sistemi-info-form {
  margin: 16px 5px 16px 5px;

  @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
  @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
  @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

  .sistemi-info-form-ctrl-label {
    margin: 16px 0 3px 16px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .sistemi-info-form-ctrl-switch {

    display: flex;
    margin-top: 8px;

    span {
      color: $color-secondary-text;
      font-size: 1.2em;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .submitsistemi {
    margin-top: 16px;
  }

  #sistemi-opt-submit {
    display: block;
    margin: 0 auto;
    background-color: $color-contrast;
    color: $color-primary-text-reverse;
    font-weight: bold;
  }
}

// endregion




// region CLOUD

#cloud-body-area {

  margin: 0 16px;
}

#cloud-body-area-line01 {
  margin-top: 32px;
  color: $color-contrast;
}

#cloud-body-area-intro {
  background-color: $color_main_back;
  margin: 16px -16px 0 -16px;
  padding-bottom: 16px;

  img {
    z-index: 10;
    position: relative;
    left: 55px;
    top: 36px;
    width: 180px;

    @media (min-width: $media_tablet) { left: 55px; }
    @media (min-width: $media_monitor) { left: 140px; }
    @media (min-width: $media_over) { left: 200px; }
  }

  p {
    font-size: 0.9em;
    position: relative;
    z-index: 20;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }
}

#cloud-body-area-line02 {
  margin: 32px 30px 0 30px;
  font-size: 1.3em;
  color: $color_secondary_text;
  text-align: justify;
  font-family: "Chivo", serif;

  .line02-center {

    text-align: center;

    li {
      font-size: 1.2em;
    }
  }
}

#cloud-2box-section {

  p {
    margin: 16px 30px 0 30px;
    font-size: 1.2em;
    color: $color_secondary_text;
    text-align: justify;
    font-family: "Chivo", serif;
  }

  h2 {
    margin-top: 16px;
    font-size: 1.5em;
    color: $color-primary-text;
    text-align: center;
    font-family: "Chivo", serif;
  }

  img {
    display: flex;
    width: 78%;
    max-width: 480px;
    margin: -12px auto 0 auto;
  }

  article {
    li {
      font-size: 1.2em;
      color: $color_secondary_text;
      text-align: center;
      font-family: "Chivo", serif;
    }
  }
}

#cloud-analisi-2-subjects {

  margin: 32px -16px 0 -16px;

  div {
    div {

      padding-bottom: 16px;

      &:first-child {
        background-color: $color-back-gray;
      }

      &:last-child {
        background-color: $color_main_back;

        ul {
          text-align: center;
          font-size: 1.2em;
        }
      }

      h2 {
        margin: 16px 0 32px 0;
        font-size: 1.5em;
        color: $color-primary-text;
        text-align: center;
        font-family: "Chivo", serif;
      }

      p {
        color: $color-secondary-text;
        font-family: "Chivo", serif;
        font-size: 1.2em;
        padding: 0 32px;
        text-align: justify;



        @media (min-width: $media_monitor) { padding: 0 64px; }
        @media (min-width: $media_over) { padding: 0 96px; }
      }
    }a {
       color: $color-primary-text;
       text-decoration: none;
       font-weight: bold;

       &:hover {
         color: $color-primary-text;
         text-decoration: none;
       }
     }
  }
}

#cloud-info {
  margin: 0 -16px 0 -16px;
  padding: 32px 0 32px 0;
  background-color: $color-back-contrast;

  h3 {
    font-size: 1.5em;
    color: $color-contrast;
    text-align: center;
    font-family: "Chivo", serif;
  }

  h2 {
    margin: 16px 15% 0 15%;
    text-align: center;
    color: $color-secondary;
    font-family: "Chivo", serif;
    font-size: 1.2em;
    line-height: 26px;

    a {
      color: $color-primary-text;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $color-primary-text;
        text-decoration: none;
      }
    }
  }
}

#cloud-info-form {
  margin: 16px 5px 16px 5px;

  @media (min-width: $media_tablet) { margin: 16px 30px 16px 30px; }
  @media (min-width: $media_monitor) { margin: 16px 50px 16px 50px; }
  @media (min-width: $media_over) { margin: 16px 50px 16px 50px; }

  .cloud-info-form-ctrl-label {
    margin: 16px 0 3px 16px;
    font-weight: bold;
    font-size: 1.2em;
  }

  .cloud-info-form-ctrl-switch {

    display: flex;
    margin-top: 8px;

    span {
      color: $color-secondary-text;
      font-size: 1.2em;

      a {
        text-decoration: none;
        color: $color-primary-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .submitcloud {
    margin-top: 16px;
  }

  #cloud-opt-submit {
    display: block;
    margin: 0 auto;
    background-color: $color-contrast;
    color: $color-primary-text-reverse;
    font-weight: bold;
  }
}

// endregion
