
#annoying-bar {

  width: 100%;
  min-height: 120px;
  display: none;
  position: fixed;
  bottom: 0;
  background-color: #ee923f;
  border-top: #7f7c7c 3px solid;
  padding-top: 40px;
  z-index: 99;

  p {
    text-align: center;
    font-size: 1.2em;

    a, span {
      color: #fdfdfd;
      font-size: 1.3em;
      font-weight: bold;
      text-decoration: none;
      padding-left: 8px;

      &:hover {

        cursor: pointer;
        text-decoration: none;
      }
    }
  }

}