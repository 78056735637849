//
// COLORS
//
$color-primary: #017abc;
$color-secondary: #01415f;
$color-secondary-light: #7b9cbc;
$color-contrast: #e95420;

$color-primary-text: $color-primary;
$color-primary-text-black: $color-secondary;
$color-primary-text-reverse: #E6E6E6;
$color-secondary-text: #404040;

$color-primary-back: #FFFFFF;
$color_main_back: #EDF4FF;
$color_back_light: #c1d5ee;
$color_back_light_green: #c1eec6;
$color_back_gray: #f7f7f7;
$color-back-contrast: #faf2ef;
$color_back_middle: #036cb1;
$color_back_dark: #404040;

$color_title_h1: $color-primary;
$color_title_h2: $color-primary;
$color_title_h3: $color-primary;
$color_link: $color-secondary;
$color_semantica: #0089c0;

$color_panel1: #cee1e3;
$color_panel2: #f6f3e8;
$color_panel3: #d4eaea;
$color_panel4: #ecf2f6;
$color_panel5: #eaf5f0;



// DIMENSIONS

$area_max_width: 1920px;
$area_large_max_width: 1280px;
$area_medium_max_width: 996px;


// MEDIA
$media_smartphone: 200px;
$media_tablet: 480px;
$media_monitor: 1280px;
$media_over: 1920px;



// region MACRO

@mixin boxShadow ($first, $second, $third, $color) {
  -moz-box-shadow: $first $second $third $color;
  -webkit-box-shadow: $first $second $third $color;
  box-shadow: $first $second $third $color;
}

@mixin boxShadow5 ($first, $second, $third, $four, $color) {
  -moz-box-shadow: $first $second $third $four $color;
  -webkit-box-shadow: $first $second $third $four $color;
  box-shadow: $first $second $third $four $color;
}

// endregion