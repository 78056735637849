@import "commonstyle";

body {
  margin: 0;
  min-width: 50px !important;
}


.topbar {
  background: $color-primary;
  color: #cccccc;
  height: 80px;
  width: 100%;
  font-family: "Chivo", serif;
  font-size: 16px;
  position: relative;
  z-index: 30;

  @include boxShadow5 (-2px, 2px, 10px, 4px, $color-secondary);

  .mdl-grid {
    height: 100%;
    max-width: $area_max_width;
    margin: 0 auto !important;
  }

  .topbar-logo {



    a {
      text-decoration: none;
      outline: 0;


      img {
        display: block;
        margin: auto;
        width: 24px;
        @include boxShadow (0, 0, 10px, $color-secondary);
        border-radius: 7px;

        &:hover {
          @include boxShadow (0, 0, 10px, $color-secondary-light);
        }
      }
    }
  }

  #topbar-language-selector {
    outline: 0;
    text-align: center;

    span {
      font-size: 24px;
      cursor: pointer;
    }
  }

  #topbar-language-menu {
    position: absolute;
    top: 58px;
    left: 0;
    background: $color-secondary;
    border-radius: 8px;
    display: none;
    @include boxShadow (0, 0, 2px, $color-secondary-light);
    z-index: 900;

    //
    // Punta superiore
    //
    div {
      position: absolute;
      top: -5px;
      right: 21px;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background: $color-secondary;
    }

    //
    // Elenco
    //
    ul {
      padding: 0;
      width: 100%;
      list-style-type: none;
      cursor: pointer;
      margin: 8px 0 8px 0;

      li {
        text-align: center;
        margin: 0 18px 0 18px;

        color: $color-primary-text-reverse;
        font-weight: bold;

        &:hover {
          color: white;
        }
      }
    }
  }


  .topbar-menu-desktop {

    a {
      font-size: 18px;
      padding: 4px 12px 4px 12px;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      color: $color-primary-text-reverse;

      &:hover {
        background: $color_back_middle;
        border-radius: 20px;
        @include boxShadow (0, 0, 2px, $color-secondary-light);
      }
    }

    .topbar-menu-desktop-selected {
      @include boxShadow5 (0, 8px, 6px, -6px, $color-secondary-light);
    }

    .topbar-menu-desktop-unselected {
      border-bottom: 0;
    }
  }


  .topbar-menu-mobile {

    width: 100%;

    #topbar-menu-mobile-selector {

      text-align: center;

      span {
        font-size: 30px;
      }
    }

    #topbar-menu-mobile-menu {

      position: absolute;
      top: 80px;
      left: 0;
      display: none;
      text-align: center;
      background: $color-primary;
      border-radius: 0 0 16px 16px;
      border-top: 2px solid $color-secondary;
      padding: 28px 20px 16px 20px;

      a {
        font-size: 20px;
        color: $color-primary-text-reverse;
        text-decoration: none;
        outline: none;
      }
    }
  }

}


.footer {
  background: $color_back_dark;
  color: $color-primary-text-reverse;
  min-height: 160px;
  width: 100%;
  text-align: center;
  font-family: "Chivo", serif;
  font-size: 16px;
  padding-top: 40px;
  position: relative;
  z-index: 50;

  .mdl-grid {
    height: 50%;
    max-width: $area_max_width;
  }

  .logo-s7a {

    width: 100%;
    margin-top: 40px;

    a {
      margin: 0 auto;
      color: $color_semantica;
      text-decoration: none;
    }

    p {
      margin: 1px 0 1px 0;
      font-size: 12px;
    }
  }

  .section-area {

    h2 {
      font-size: 1.2em;
      font-family: "Chivo", serif;
      margin: 0 0 4px 0;
    }

    a {
      display: block;
      font-size: 1em;
      text-decoration: none;
      color: $color-primary-text-reverse;
      border-bottom: $color-primary 1px solid;
      padding-bottom: 2px;
      margin: 0 20% 8px 20%;

      &:hover {

        text-decoration: none;
        color: $color-primary-text-reverse;
      }
    }

    div {
      p {
        display: block;
        margin-bottom: 0;

        &:first-of-type {

          margin-top: 16px;
        }

        &:last-of-type{

          margin-top: 2px;
        }
      }
    }


  }

  .footer-social {

    text-align: left;

    span {
      margin-right: 16px;
      font-size: 1.5em;
    }
  }

  #carbonNeutral {

    margin-right: 0;
    bottom: 0;
    position: relative;

    @media (min-width: $media_monitor) {
      margin-right: 70px;
      bottom: 27px;
      float: right;
    }

    svg {
      color: lightgreen;
    }
  }
}


#fullScreenWaitingBackground {

  display: none;

  .s7a-loader-lds-ring {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  .waitingFullOpacity {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 9000;
    //
    // E' meglio non usare opacity, altrimenti tutti gli elementi contenuti nel div
    // non possono avere un valore superiore a quello del padre.
    //
    background: rgba(0, 0, 0, 0.4);
  }
}



// region BODY LEFT BAR

.body-leftbar-area {

  max-width: $area_max_width;
  margin: 0 auto;
}

#left-bar {

  position: relative;
  left: 0;
  top: 0;

  background-color: $color_back_light;



  @media (min-width: $media_over) {

    background-color: transparent;
  }

}

.leftbarMenuItem {

  &:first-child {
    margin-top: 80px;
  }

  position: relative;
  margin-top: 16px;
  padding: 0 0 6px 16px;
  cursor: pointer;

  border-bottom: 2px solid $color-primary-text;

  img {
    max-width: 24px;
  }

  span {
    margin-left: 16px;
    font-family: "Chivo", serif;
    font-size: 1.4em;
    color: $color-primary-text-black;
    vertical-align: middle;
    cursor: pointer;
  }

  @media (min-width: $media_monitor) {

    padding-left: 40px;
  }

}

.leftbarMenuItemSmall {

  &:first-child {
    margin-top: 50px;
  }


  margin: 16px 0 0 16px;

  max-width: 64px;
  cursor: pointer;

  img {
    display: block;
    margin: 0 auto;
    padding-top: 16px;
    max-width: 32px;
  }

}

// endregion





